import logo from './logo.svg';
import './App.css';
import React,  {Alert,Component} from 'react';
import Menu from './components/Menu';
import Accueil from './components/Accueil';
import Login from './components/Login';
import Category from './components/Category';
import Carouseldata from './components/Carousel';
import Partner from './components/Partner';
import Profil from './components/Profil';
import { BrowserRouter as Router, Route, Routes, Switch} from 'react-router-dom';
import packageJson from '../package.json';
import ls from "local-storage";


class App extends Component{
    
  constructor(props) {
  super(props);
    this.state = {
      loaddata : true,
      width: 0,
      height: 0,
      scrollpage:0,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      
  }
  
  async componentDidMount() {
    
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('scroll', this.updateWindowDimensions);
      
  };

  componentWillMount () {

    ls.set('backend', packageJson.backlink);

    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('scroll', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight, scrollpage: window.scrollY });
  }

  scrolltop(){
    window.scrollTo(0, 0)
  }
  
  render() {

        return (

          <div className='noselect'>

                <div style={{position:"fixed", width:"100%", top:0, zIndex: '1000'}}>

                  <Menu />

                </div>

                    <Router>

                      <Switch>
                            
                            <Route  path="/" exact component={Accueil}></Route>
                            <Route  path="/Accueil" exact component={Accueil} title="Accueil"></Route>
                            <Route  path="/Login" exact component={Login} title="Login"></Route>
                            <Route  path="/Category" exact component={Category} title="Category"></Route>
                            <Route  path="/Carousel" exact component={Carouseldata} title="Carousel"></Route>
                            <Route  path="/Partner" exact component={Partner} title="Partner"></Route>
                            <Route  path="/Profil" exact component={Profil} title="Profil"></Route>
                                                             
                      </Switch>

                  </Router>          

              </div>

        );

      }

}

export default App;
