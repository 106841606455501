
import React,  {Alert,Component} from 'react';
import {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import '../App.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col} from 'react-bootstrap';
import Loader from "react-js-loader";
import ls from "local-storage";

var errocode = 0;


class Category extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        backend:'',
        linkimage:'',
        selectedFile: null,
        selectedOption: null,
        listuser: [],
        modaladd:false,
        modalupdate: false,
        modaldelete: false,
        modaladdimage : false,
        listcategory : [],
        listimage : [],
        idimagephoto :0,
        titletoursedit:'',
        idcategory:0,
        title:'',
        description:'',
        roadmap:'',
        loadershow:false,
        imagefilename:'',
        editorContentHtml:'',
        tokenvalue : '',
        tokenexist:true,
        imageselect:'',
        imageshow:'',
        namecategory:'',
       
      };

  }

  componentWillMount() {

    document.title = "Category Data";

    var token = ls.get('token');
  
    if(token === null || token === 'Undefined'){

      this.setState({ tokenexist: false})

      document.location.href = '/login';

      return;

    }else{

      this.setState({ tokenvalue: token})
      
    }

  }
    
  async componentDidMount() { 

    var backend = ls.get('backend');
    
    this.setState({
      backend: backend,
      linkimage:backend,
    })   

    var token = ls.get('token');

    fetch(backend + `category`,{ 

      mode:'cors',
      method:'get',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

      this.setState({ listcategory: data})

    this.setState({
      idcategory:0,
      name: '',
      description:'',
      selectedFile:'',
      imageselect:'',
      modaladd: false,
    })

    })
    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        ls.remove("token");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    }); 

    {/** 
    //liste des categories
    const responsecategory = await fetch(backend+ 'listcategory');
    const datacategory = await responsecategory.json();
    this.setState({ listcategory: datacategory}) 
    */}
  
  }

  onFileChange = event => { 
    // Update the state 
    this.setState({
      selectedFile: event.target.files[0],
      imagefilename : event.target.files[0].name,
      imageselect: URL.createObjectURL(event.target.files[0]),
     }); 

  }; 
   
  // On file upload (click the upload button) 
  addcategory (){

    if(this.state.selectedFile === null || this.state.selectedFile === 'null'){

      this.setState({
        loadershow: false,
        textloading:'Please select a file.'
      });   

      return;

    }

    if(this.state.selectedFile.size > 25 * 1024 * 1024)
    {
      this.setState({
        loadershow: false,
        textloading:'Image need to be under 25Mb.'
      });
      
      return;

    }


    if(this.state.name === '' ){

      this.setState({
        loadershow: false,
        textloading:'Please put a name of category.'
      });
      
      return;
    
    }

    if(this.state.description === '' ){

      this.setState({
        loadershow: false,
        textloading:'Please put a description of category.'
      });
      
      return;
    
    }

    this.setState({
      loadershow: true,
      textloading:'Uploading file ...'
    });

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("namecategory",this.state.name);
    formData.append("descriptioncategory",this.state.description);
    formData.append("imagecategory",this.state.selectedFile);
  
    var token = ls.get('token');
  
    fetch(this.state.backend + `category`,{     
  
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

      this.setState({ listcategory: data.data})

      //alert(JSON.stringify(data).replace(/\"/g, ""))

      //return;


    this.setState({
      idcategory:0,
      name: '',
      description:'',
      selectedFile:'',
      imageselect:'',
      imagefilename : '',
      loadershow: false,
      modaladd: false,
    })

    //this.refreshdata();

    })
    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        ls.remove("token");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    }); 

}

loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:null,
    idcategory: 0,
    name: '',
    description: '',
    extensionfile:'',
    imagefilename:'',
    textloading:'',
    modaladd:false
  });
  
}

showaddcategory(){

  this.setState({
    idcategory: 0,
    name: '',
    description: '',
    roadmap:'',
    modaladd: true
  })

}

showupdatecategory(idcategory,name,description,imgcategory){

  this.setState({
    idcategory: idcategory,
    name: name,
    description: description,
    imageshow : imgcategory,
    modalupdate: true
  })

}

updatecategory(){

  this.setState({
    loadershow: true,
    textloading: 'Updating data and file ...'
  });


  if(this.state.selectedFile.size > 25 * 1024 * 1024)
    {
      this.setState({
        loadershow: false,
        textloading:'Image need to be under 25Mb.'
      });
      
      return;

    }


    if(this.state.name === '' ){

      this.setState({
        loadershow: false,
        textloading:'Please put a name of category.'
      });
      
      return;
    
    }

    if(this.state.description === '' ){

      this.setState({
        loadershow: false,
        textloading:'Please put a description of category.'
      });
      
      return;
    
    }

    this.setState({
      loadershow: true,
      textloading:'Update category ...'
    });

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("namecategory",this.state.name);
    formData.append("descriptioncategory",this.state.description);

    if(this.state.selectedFile.size > 0)
    {

      formData.append("imagecategory",this.state.selectedFile);

    }

    formData.append('_method', 'PUT');
    
    var token = ls.get('token');
  
    fetch(this.state.backend + `category/` + this.state.idcategory,{     
  
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

      this.setState({ listcategory: data.data})


    this.setState({
      idcategory:0,
      name: '',
      description:'',
      selectedFile:'',
      imageselect:'',
      imagefilename:'',
      modalupdate : false,
      loadershow:false,
    })

    //this.refreshdata();

    })
    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        ls.remove("token");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    }); 

}

showdeletecategory(idcategory,name,imagecategory){
  
  this.setState({
    idcategory: idcategory,
    name:name,
    imageshow : imagecategory,
    modaldelete: true
  })

}

async selectimagecategory(idcategory,name){

  this.setState({
    loadershow: false,
    selectedFile:null,
    idcategory: idcategory,
    namecategory: name,
    modaladdimage: true
  })

  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("idcat",idcategory);

  var token = ls.get('token');

  const response = await fetch(this.state.backend + 'listimagecategory',{                  
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData
  });
  const data = await response.json();

  if(data.length>0){
    this.setState({
      listimage:data,
    })
  }else{
    this.setState({
      listimage:[],
    })
  }
  

}

addimagecategory = () => { 

  if(this.state.selectedFile === null || this.state.selectedFile === 'null'){

    this.setState({
      loadershow: false,
      textloading:'Please select a file.'
    });   

    return;

  }

  if(this.state.selectedFile.size > 25 * 1024 * 1024)
    {
      this.setState({
        loadershow: false,
        textloading:'Image need to be under 25Mb.'
      });
      
      return;

    }


    if(this.state.name === '' ){

      this.setState({
        loadershow: false,
        textloading:'Please put a name of category.'
      });
      
      return;
    
    }

  // Create an object of formData 
  const formData = new FormData(); 

  // Update the formData object 
  formData.append("nom",this.state.name);
  formData.append("imagephoto",this.state.selectedFile);
  formData.append("idcat",this.state.idcategory);

  var token = ls.get('token');

  fetch(this.state.backend + `photo`,{     

    mode: 'cors', 
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

  })

  .then(res => {

    errocode = res.status;

    if (!res.ok) {
        
        return res.json().then(json => { throw json; });

    }

    return res.json();

  })
  .then(data => {
    
  this.setState({ listimage: data.data})

  this.setState({
    name: '',
    description:'',
    selectedFile:'',
    imageselect:'',
    imagefilename:'',
  })


  })
  .catch(err => {
      alert("Erreur :" + errocode + ", "+err)

      this.setState({
       loadershow: false
      })

  }); 


}

showdeleteimagecategory(idphoto,imageselect){
  this.setState({
    loadershow: false,
    selectedFile:null,
    idimagephoto: idphoto,
    imageshow : imageselect,
    imageselect : imageselect,
    modaldeleteimage: true
  })
}

deleteimagecategory(){
  
  var token = ls.get('token');

  fetch(this.state.backend + `photo/`+ this.state.idimagephoto,{ 

    mode:'cors',
    method:'delete',
    headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},

  })

  .then(res => {

    errocode = res.status;

    if (!res.ok) {
        
        return res.json().then(json => { throw json; });

    }

    return res.json();

  })
  .then(data => {

    this.setState({ listimage: data.data})

  this.setState({
    name: '',
    description:'',
    selectedFile:null,
    idimagephoto: 0,
    imageshow : '',
    imageselect : '',
    loadershow: false,
    modaldeleteimage: false,
  })

  })
  .catch(err => {

    if(errocode == 401 || errocode == 0){
      
      ls.remove("token");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  })

}

deletecategory(){

  var token = ls.get('token');

  fetch(this.state.backend + `category/`+ this.state.idcategory,{ 

    mode:'cors',
    method:'delete',
    headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},

  })

  .then(res => {

    errocode = res.status;

    if (!res.ok) {
        
        return res.json().then(json => { throw json; });

    }

    return res.json();

  })
  .then(data => {

    this.setState({ listcategory: data.data})

  this.setState({
    idcategory:0,
    name: '',
    description:'',
    selectedFile:null,
    imageselect:'',
    loadershow: false,
    modaldelete: false,
  })

  })
  .catch(err => {

    if(errocode == 401 || errocode == 0){
      
      ls.remove("token");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  })

}

async refreshdata(){

  //liste des categories
  const responsecategory = await fetch(this.state.backend+ 'listcategory');
  const datacategory = await responsecategory.json();
  this.setState({ listcategory: datacategory}) 

  this.setState({
    loadershow: false,
    selectedFile:null,
    idcategory: 0,
    name: '',
    description: '',
    extensionfile:'',
    imagefilename:'',
    textloading:'',
    selectedFile:'',
  })

}

onEditorStateChange = (editorState) => {
    
  this.setState({
    editorState
  });

  this.setState({
    //texthtml:convertToHTML(editorState.getCurrentContent()),
  });

};

onChange = (editorState) => {
  this.setState({
    editorState
  });
  
}
    
  render() {


          return (
              
                <div className="App" style={{marginTop:'100px'}}>
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }

                    <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({modaladd:true})} variant="primary">ADD CATEGORY</Button>
                                  
                      {this.state.modaladd === true ?

                        <Modal

                        show={this.state.modaladd}
                        onHide={ () => this.setState({modaladd:false, idcategory:0, name: '', description:'', selectedFile:'', imageselect:'',loadershow: false})}
                        dialogClassName="modal-dialog"
                        aria-labelledby="example-custom-modal-styling-title" centered
                        >
                          <Modal.Body>
                              <div className="d-flex flex-column justify-content-center">

                                <label>Name :</label>
                                <input style={{width:'100%', marginTop:'10px', marginBottom:'10px'}}type="text" className="form-control" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value})} />
                              
                                <label>Description :</label>
                                <textarea type="textarea" rows={3} style={{width:'100%'}} className="form-control" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value})} />

                                {this.state.imageselect === '' ?

                                  <div style={{textAlign:"center", marginTop:"20px"}}>
                                      No select file                    
                                  </div>

                                  :

                                  <div className="imagemodal">
                                    <img alt={this.state.imagefilename} src={this.state.imageselect} style={{width:'50%', height:'50%', margin:'10px'}}/> 
                                    <p>{this.state.imagefilename}</p>
                                  </div>

                                }

                                <label htmlFor="filePickerimgcat" style={{background:"#ffc107", padding:"5px 10px", marginTop:'20px', color:'white', borderRadius:'5px',textAlign:'center' }}>Select file</label>
                                <input id="filePickerimgcat" style={{visibility:"hidden"}} name="imgcat" type="file" accept="image/*" onChange={this.onFileChange}/>

                                {this.state.loadershow === true ?
                              

                                  <Loader type="spinner-cub" bgColor={'#0275d8'} title={this.state.textloading} color={'#0275d8'} size={100} />
                                    
            
                                  :
            
                                  null
            
                                }
                                
                                <div className='d-flex flex-row justify-content-center'>
                                            
                                    <Button className="btn"style={{ margin:"10px", width:'150px'}} onClick={ () => this.setState({modaladd:false, idcategory:0, name: '', description:'', selectedFile:'', imageselect:'',loadershow: false})} variant="primary">Close</Button>
                                    {this.state.selectedFile === null ?

                                      null

                                      :

                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.addcategory()} variant="success">Add category</Button>

                                    }
                                    
                                </div>

                            </div>          
                          
                        </Modal.Body>
                        </Modal>

                        :

                        null

                        }

                  
                  {this.state.modalupdate === true ?

                  <Modal
                  
                  show={this.state.modalupdate}
                  onHide={ () => this.setState({modalupdate:false, idcategory:0, name: '', description:'', selectedFile:'', imageselect:'',loadershow: false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >

                      <Modal.Body>
                          <div className="d-flex flex-column justify-content-center">

                            <label>Name :</label>
                            <input style={{width:'100%', marginTop:'10px', marginBottom:'10px'}}type="text" className="form-control" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value})} />
                          
                            <label>Description :</label>
                            <textarea type="textarea" rows={3} style={{width:'100%'}} className="form-control" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value})} />

                            <div className="container">
                              <div className="row">
                                <div className="col justify-content-center">
                                  <p style={{textAlign:"center", marginTop:"20px"}}>Last image</p>
                                  <div className='d-flex justify-content-center'>
                                    <img src={this.state.linkimage + this.state.imageshow} style={{width:"150px", height:"150px"}} className="d-flex justify-content-center"/>
                                  </div>
                                </div>
                                <div className="col justify-content-center">

                                  {this.state.imageselect === '' ?

                                  <div style={{textAlign:"center", marginTop:"20px"}}>
                                      No select file                    
                                  </div>

                                  :

                                  <div className="imagemodal">
                                    <img alt={this.state.imagefilename} src={this.state.imageselect} style={{width:'50%', height:'50%', margin:'10px'}}/> 
                                    <p>{this.state.imagefilename}</p>
                                  </div>

                                  }

                                </div>
                              </div>
                            </div>

                           

                            

                            <label htmlFor="filePicker" style={{background:"#ffc107", padding:"5px 10px", marginTop:'20px', color:'white', borderRadius:'5px',textAlign:'center' }}>Select file</label>
                            <input id="filePicker" style={{visibility:"hidden"}} name="imgcat" type="file" accept="image/*" onChange={this.onFileChange}/>

                            {this.state.loadershow === true ?
                              

                              <Loader type="spinner-cub" bgColor={'#0275d8'} title={this.state.textloading} color={'#0275d8'} size={100} />
                                
        
                              :
        
                              null
        
                            }

                            <div className='d-flex flex-row justify-content-center'>
                                        
                            <Button className="btn"style={{ margin:"10px", width:'150px'}} onClick={ () => this.setState({modalupdate:false, idcategory:0, name: '', description:'', selectedFile:'', imageselect:'',loadershow: false})} variant="primary">Close</Button>
                            <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.updatecategory()} variant="success">Update</Button>
                                
                            </div>

                        </div>          
                      
                    </Modal.Body>

                  </Modal>

                  :

                  null

                  }

                  {this.state.modaldelete === true ?

                  <Modal

                  show={this.state.modaldelete}
                  onHide={ () => this.setState({modaldelete:false, idcategory:0, name: '', description:'', selectedFile:'', imageselect:'',loadershow: false})}
                  dialogClassName="modal-dialog"
                  aria-labelledby="example-custom-modal-styling-title" centered
                  >
                    <Modal.Body>
                                
                    <div className="d-flex flex-row justify-content-center">
                                      
                        <div className="d-flex flex-column" style={{margin:"20px"}}>
                            
                            <div className="d-flex flex-column">

                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>Would you like to delete</p>
                                <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.name} &nbsp;</p>

                                <div className='d-flex justify-content-center'>
                                  <img src={this.state.linkimage + this.state.imageshow} style={{width:"150px", height:"150px"}} className="d-flex justify-content-center"/>
                                </div>
                                <form>

                                <div className='row text-white d-flex align-items-center'>
                                            
                                      <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({modaldelete:false, idcategory:0, name: '', description:'', selectedFile:'', imageselect:'',loadershow: false})} variant="primary">Close</Button>
                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.deletecategory()} variant="danger">Delete</Button>

                                </div>
                                
                              </form>
                            </div>
                            
                        </div>

                    </div>    

                  </Modal.Body>
                  </Modal>

                  :

                  null

                  }

                  <div className='centrervue' >
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          
                          <TableCell >Images</TableCell>
                          <TableCell >Name</TableCell>
                          <TableCell >Description</TableCell>
                          <TableCell >Add image</TableCell>
                          <TableCell >Update</TableCell>
                          <TableCell >Delete</TableCell>
                          
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.listcategory.map((category) => (
                          <TableRow key={category.id_category}>

                            <TableCell component="th" scope="row" className='verticalcenter'><img src={this.state.linkimage + category.category_img} style={{width:"150px", height:"150px"}} className="verticalcenter"/></TableCell>
                            <TableCell >{category.category_name}</TableCell>
                            <TableCell >{category.category_description}</TableCell>
                            <TableCell ><button type="submit" className="btn btn-success btn-circle btn-xl" onClick={() => this.selectimagecategory(category.id_category,category.category_name)}><span><AddIcon /></span></button></TableCell>
                            <TableCell ><button type="submit" className="btn btn-primary btn-circle btn-xl" onClick={() => this.showupdatecategory(category.id_category,category.category_name,category.category_description,category.category_img)}><span><EditIcon /></span></button></TableCell>
                            <TableCell ><button type="submit" className="btn btn-danger btn-circle btn-xl" onClick={() => this.showdeletecategory(category.id_category,category.category_name,category.category_img)}><span><DeleteIcon /></span></button></TableCell>


                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  </div> 

                  {this.state.modaladdimage === true ?

                    <Modal

                    show={this.state.modaladdimage}
                    onHide={ () => this.setState({modaladdimage:false, listimage: []})}
                    dialogClassName="modal-dialog"
                    aria-labelledby="example-custom-modal-styling-title" centered
                    >
                      <Modal.Body>

                      <div className="App">
                                              
                                <div className='d-flex flex-column centerdata'>

                                  <p style={{fontWeight:'bold'}}>{this.state.namecategory}</p>

                                    {this.state.imagefilename === null ?

                                      <div >
                                          No select file                    
                                      </div>

                                      :

                                      this.state.imagefilename

                                    }

                                    <label style={{textAlign:'left'}}>Name :</label>
                                    <input style={{width:'100%', marginTop:'10px', marginBottom:'10px'}}type="text" className="form-control" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value})} />

                                    <label htmlFor="filePicker" style={{background:"#0d6efd", padding:"5px 10px", marginTop:'20px', color:'white', borderRadius:'5px',textAlign:'center' }}> Select file </label>
                                    <input id="filePicker" style={{visibility:"hidden"}} type={"file"} onChange={this.onFileChange} accept=".jpg"/>

                                    {this.state.loadershow === true ?
                                      

                                      <Loader type="spinner-cub" bgColor={'#0275d8'} title={this.state.textloading} color={'#0275d8'} size={100} />
                                        
                
                                      :
                
                                      null
                
                                    }
                                    

                                    {this.state.selectedFile === null ?

                                      null

                                      :

                                      <button onClick={this.addimagecategory} className="btn btn-success">Add picture</button> 

                                    }
                                    
                                              
                                </div> 


                            {this.state.modaldeleteimage === true ?

                                                                     
                              <div className="d-flex flex-row justify-content-center">
                                                
                                  <div className="d-flex flex-column" style={{margin:"20px"}}>
                                      
                                      <div className="d-flex flex-column">
                                          <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>Would you like to delete?</p>
                                          <div className='d-flex justify-content-center'>
                                            <img src={this.state.linkimage + this.state.imageshow} style={{width:"150px", height:"150px"}} className="d-flex justify-content-center"/>
                                          </div>
                                          <form>
  
                                          <div className='row text-white d-flex align-items-center'>
                                                      
                                                <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({modaldeleteimage:false})} variant="primary">Close</Button>
                                                <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.deleteimagecategory()} variant="danger">Delete</Button>

                                          </div>
                                          
                                        </form>
                                      </div>
                                      
                                  </div>

                              </div>    

                            :

                            null

                            }

                            <div className='centrervue' style={{height:'500px', overflow:'auto'}}>
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      
                                      <TableCell >Images</TableCell>
                                      <TableCell >Name</TableCell>
                                      <TableCell >Delete</TableCell>
                                      
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.listimage.map((imgcat) => (
                                      <TableRow key={imgcat.id_photo}>
                                         
                                        <TableCell component="th" scope="row" className='verticalcenter'><img src={this.state.linkimage  + imgcat.chemin} style={{width:"150px", height:"150px"}} className="verticalcenter"/></TableCell>
                                        <TableCell >{imgcat.nom}</TableCell>
                                        <TableCell ><button type="submit" className="btn btn-danger btn-circle btn-xl" onClick={() => this.showdeleteimagecategory(imgcat.id_photo,imgcat.chemin)}><span><DeleteIcon /></span></button></TableCell>


                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div> 
                        
                          </div>

                      
                      </Modal.Body>
                    </Modal>

                    :

                    null

                    }
               
                </div>
                
          );
        }
    
}

export default Category;
