
import React,  {Alert,Component} from 'react';
import {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import '../App.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col} from 'react-bootstrap';
import Loader from "react-js-loader";
import ls from "local-storage";

var errocode = 0;


class Profil extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        backend:'',
        linkimage:'',
        selectedFile: null,
        selectedOption: null,
        listuser: [],
        modaladd:false,
        modalupdate: false,
        modaldelete: false,
        modaladdimage : false,
        listprofil : [],
        listimage : [],
        idimagephoto :0,
        titletoursedit:'',
        idprofil:0,
        title:'',
        description:'',
        roadmap:'',
        loadershow:false,
        imagefilename:'',
        editorContentHtml:'',
        tokenvalue : '',
        tokenexist:true,
        imageselect:'',
        imageshow:'',
        nameprofil:'',
       
      };

  }

  componentWillMount() {

    document.title = "Profil Data";

    var token = ls.get('token');
  
    if(token === null || token === 'Undefined'){

      this.setState({ tokenexist: false})

      document.location.href = '/login';

      return;

    }else{

      this.setState({ tokenvalue: token})
      
    }

  }
    
  async componentDidMount() { 

    var backend = ls.get('backend');
    
    this.setState({
      backend: backend,
      linkimage:backend,
    })   

    var token = ls.get('token');

    fetch(backend + `listimageprofil`,{ 

      mode:'cors',
      method:'get',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

      this.setState({ listprofil: data})

    this.setState({
      idprofil:0,
      name: '',
      description:'',
      selectedFile:null,
      imageselect:'',
      modaladd: false,
    })

    })
    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        ls.remove("token");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    }); 

    {/** 
    //liste des categories
    const responseprofil = await fetch(backend+ 'listprofil');
    const dataprofil = await responseprofil.json();
    this.setState({ listprofil: dataprofil}) 
    */}
  
  }

  onFileChange = event => { 
    // Update the state 
    this.setState({
      selectedFile: event.target.files[0],
      imagefilename : event.target.files[0].name,
      imageselect: URL.createObjectURL(event.target.files[0]),
     }); 

  }; 
   
  // On file upload (click the upload button) 
  addprofil (){

    if(this.state.selectedFile === null || this.state.selectedFile === 'null'){

      this.setState({
        loadershow: false,
        textloading:'Please select a file.'
      });   

      return;

    }

    if(this.state.selectedFile.size > 25 * 1024 * 1024)
    {
      this.setState({
        loadershow: false,
        textloading:'Image need to be under 25Mb.'
      });
      
      return;

    }


    if(this.state.name === '' ){

      this.setState({
        loadershow: false,
        textloading:'Please put a name of profil.'
      });
      
      return;
    
    }

    this.setState({
      loadershow: true,
      textloading:'Uploading file ...'
    });

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("name",this.state.name);
    formData.append("imageprofil",this.state.selectedFile);
  
    var token = ls.get('token');
  
    fetch(this.state.backend + `profil`,{     
  
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

      this.setState({ listprofil: data.data})

      //alert(JSON.stringify(data).replace(/\"/g, ""))

      //return;


    this.setState({
      idprofil:0,
      name: '',
      description:'',
      selectedFile:null,
      imageselect:'',
      imagefilename : '',
      loadershow: false,
      modaladd: false,
    })

    //this.refreshdata();

    })
    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        ls.remove("token");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    }); 

}

loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:null,
    idprofil: 0,
    name: '',
    description: '',
    extensionfile:'',
    imagefilename:'',
    textloading:'',
    modaladd:false
  });
  
}

showaddprofil(){

  this.setState({
    idprofil: 0,
    name: '',
    description: '',
    roadmap:'',
    modaladd: true
  })

}

showupdateprofil(idprofil,name,description,imgprofil){

  this.setState({
    idprofil: idprofil,
    name: name,
    description: description,
    imageshow : imgprofil,
    modalupdate: true
  })

}

updateprofil(){

  this.setState({
    loadershow: true,
    textloading: 'Updating data and file ...'
  });

  if(this.state.selectedFile === null || this.state.selectedFile === 'null'){

    this.setState({
      loadershow: false,
      textloading:'Please select a file.'
    });   

    return;

  }

  if(this.state.selectedFile.size > 25 * 1024 * 1024)
  {
    this.setState({
      loadershow: false,
      textloading:'Image need to be under 25Mb.'
    });
    
    return;

  }



  if(this.state.selectedFile.size > 25 * 1024 * 1024)
    {
      this.setState({
        loadershow: false,
        textloading:'Image need to be under 25Mb.'
      });
      
      return;

    }

    this.setState({
      loadershow: true,
      textloading:'Update profil ...'
    });

    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("imageprofil",this.state.selectedFile);
    
    var token = ls.get('token');
  
    fetch(this.state.backend + `imageprofil`,{     
  
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

      this.setState({ listprofil: data.data})


    this.setState({
      idprofil:0,
      name: '',
      description:'',
      selectedFile:null,
      imageselect:'',
      imagefilename:'',
      modalupdate : false,
      loadershow:false,
    })

    //this.refreshdata();

    })
    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        ls.remove("token");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    }); 

}

showdeleteprofil(idprofil,name,imageprofil){
  
  this.setState({
    idprofil: idprofil,
    name:name,
    imageshow : imageprofil,
    modaldelete: true
  })

}

async selectimageprofil(idprofil,name){

  this.setState({
    loadershow: false,
    selectedFile:null,
    idprofil: idprofil,
    nameprofil: name,
    modaladdimage: true
  })

  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("idcat",idprofil);

  var token = ls.get('token');

  const response = await fetch(this.state.backend + 'listimageprofil',{                  
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData
  });
  const data = await response.json();

  if(data.length>0){
    this.setState({
      listimage:data,
    })
  }else{
    this.setState({
      listimage:[],
    })
  }
  

}

addimageprofil = () => { 

  if(this.state.selectedFile === null || this.state.selectedFile === 'null'){

    this.setState({
      loadershow: false,
      textloading:'Please select a file.'
    });   

    return;

  }

  if(this.state.selectedFile.size > 25 * 1024 * 1024)
    {
      this.setState({
        loadershow: false,
        textloading:'Image need to be under 25Mb.'
      });
      
      return;

    }


    if(this.state.name === '' ){

      this.setState({
        loadershow: false,
        textloading:'Please put a name of profil.'
      });
      
      return;
    
    }

  // Create an object of formData 
  const formData = new FormData(); 

  // Update the formData object 
  formData.append("nom",this.state.name);
  formData.append("imagephoto",this.state.selectedFile);
  formData.append("idcat",this.state.idprofil);

  var token = ls.get('token');

  fetch(this.state.backend + `photo`,{     

    mode: 'cors', 
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

  })

  .then(res => {

    errocode = res.status;

    if (!res.ok) {
        
        return res.json().then(json => { throw json; });

    }

    return res.json();

  })
  .then(data => {
    
  this.setState({ listimage: data.data})

  this.setState({
    name: '',
    description:'',
    selectedFile:null,
    imageselect:'',
    imagefilename:'',
  })


  })
  .catch(err => {
      alert("Erreur :" + errocode + ", "+err)

      this.setState({
       loadershow: false
      })

  }); 


}

showdeleteimageprofil(idphoto,imageselect){
  this.setState({
    loadershow: false,
    selectedFile:null,
    idimagephoto: idphoto,
    imageshow : imageselect,
    imageselect : imageselect,
    modaldeleteimage: true
  })
}

deleteimageprofil(){
  
  var token = ls.get('token');

  fetch(this.state.backend + `photo/`+ this.state.idimagephoto,{ 

    mode:'cors',
    method:'delete',
    headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},

  })

  .then(res => {

    errocode = res.status;

    if (!res.ok) {
        
        return res.json().then(json => { throw json; });

    }

    return res.json();

  })
  .then(data => {

    this.setState({ listimage: data.data})

  this.setState({
    name: '',
    description:'',
    selectedFile:null,
    idimagephoto: 0,
    imageshow : '',
    imageselect : '',
    loadershow: false,
    modaldeleteimage: false,
  })

  })
  .catch(err => {

    if(errocode == 401 || errocode == 0){
      
      ls.remove("token");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  })

}

deleteprofil(){

  alert(this.state.idprofil)

  var token = ls.get('token');

  fetch(this.state.backend + `profil/`+ this.state.idprofil,{ 

    mode:'cors',
    method:'delete',
    headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},

  })

  .then(res => {

    errocode = res.status;

    if (!res.ok) {
        
        return res.json().then(json => { throw json; });

    }

    return res.json();

  })
  .then(data => {

    this.setState({ listprofil: data.data})

  this.setState({
    idprofil:0,
    name: '',
    description:'',
    selectedFile:null,
    imageselect:'',
    loadershow: false,
    modaldelete: false,
  })

  })
  .catch(err => {

    if(errocode == 401 || errocode == 0){
      
      ls.remove("token");
      document.location.href = "/login";

      return

    }

    alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

    this.setState({
      loadershow: false
    })

  })

}

async refreshdata(){

  //liste des categories
  const responseprofil = await fetch(this.state.backend+ 'listprofil');
  const dataprofil = await responseprofil.json();
  this.setState({ listprofil: dataprofil}) 

  this.setState({
    loadershow: false,
    selectedFile:null,
    idprofil: 0,
    name: '',
    description: '',
    extensionfile:'',
    imagefilename:'',
    textloading:'',
    selectedFile:null,
  })

}

onEditorStateChange = (editorState) => {
    
  this.setState({
    editorState
  });

  this.setState({
    //texthtml:convertToHTML(editorState.getCurrentContent()),
  });

};

onChange = (editorState) => {
  this.setState({
    editorState
  });
  
}
    
  render() {


          return (
              
                <div className="App" style={{marginTop:'100px'}}>
                  
                  {this.state.tokenvalue === '' ?
                            

                            <Redirect push to="/Login"/>
                              

                            :

                            null

                    }

                    

                    <div className="container">

                      <div className="row">

                        <div className="col">
                          <p>Last image</p>

                          {this.state.listprofil.map((imgprof) => {
                            return (

                                    <img src={this.state.linkimage + imgprof.img_profil}  style={{width:"250px", height:"250px", borderRadius:"250px", marginLeft:"5px"}}/>

                              );
                          })}

                        </div>

                        <div className="col d-flex flex-column justify-content-center">

                          {this.state.imageselect === '' ?

                            <div style={{textAlign:"center", marginTop:"20px"}}>
                                No select file                    
                            </div>

                            :

                            <div className="imagemodal">
                              <p>New image</p>
                              <img alt={this.state.imagefilename} src={this.state.imageselect} style={{width:"250px", height:"250px", borderRadius:"250px", marginLeft:"5px"}}/> 
                              <p>{this.state.imagefilename}</p>
                            </div>

                            }

                            <div className='selectbutton'> 
                            <label htmlFor="filePickerimgcat" style={{background:"#0d6efd", padding:"5px 10px", marginTop:'20px', color:'white', borderRadius:'5px',textAlign:'center', width:'200px' }} variant="primary" >Select file</label>
                            <input id="filePickerimgcat" style={{visibility:"hidden"}} name="imgcat" type="file" accept="image/*" onChange={this.onFileChange}/>
                            </div>
                            
                          
                            {this.state.loadershow === true ?


                            <Loader type="spinner-cub" bgColor={'#0275d8'} title={this.state.textloading} color={'#0275d8'} size={100} />
                              

                            :

                            null

                            }

                            <div className='d-flex justify-content-center'>
                                      
                              {this.state.selectedFile === null ?

                                null

                                :

                                <Button className="btn" style={{ margin:"10px",right: 0, width:'200px'}} onClick={() => this.updateprofil()} variant="success">Update</Button>

                              }
                              
                            </div>

                        </div>

                      </div>
                     
                    </div>


                </div>
                
          );
        }
    
}

export default Profil;
