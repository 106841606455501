import '../App.css';
import React,  {Alert,Component,PointerEvent} from 'react';
import { useRef, useState } from "react";
import { Nav,Navbar,Form,Button,Container,Row,Col,NavDropdown,Card,Modal} from 'react-bootstrap';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import MailIcon from '@material-ui/icons/Mail';
import CallIcon from '@material-ui/icons/Call';
import HouseIcon from '@material-ui/icons/House';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { BrowserRouter as Router, Switch, Route, Link,Redirect,HashRouter } from 'react-router-dom';
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import Carousel from 'react-bootstrap/Carousel';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import queryString from 'query-string';
import ls from "local-storage";


class Accueil extends Component{
    
  constructor(props) {
  super(props);
    this.state = {
      backend:'',
      linkimage:'',
      imagescarousel:[],
      listcategory:[],
      listpartner:[],
      listimageprofil:[],
      idcategory:0,
      namecategory:'',
      descriptioncategory:'',
      imagecategory:'',
      loaddata : true,
      show: false,
      setShow: false,
      isModalVisible: false,
      isModalloderVisible : true,
      accueiltitre: '',
      accueilimage: '',
      touteslesarticles: [],
      touteslestours: [],
      listimage:[],
      idtourselect:0,
      title:'',
      description:'',
      roadmap:'',
      extensionfile:'',
      touteslesaccueil: [],
      touteslesgallery: [],
      gallery: [],
      allopinionuser:[],
      width: 0,
      height: 0,
      scrollpage:0,
      ratingvalue:0,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.detailcategory = this.detailcategory.bind(this);
    
      
  }
  
  async componentDidMount() {

    var backend = ls.get('backend');
    
    this.setState({
      backend: backend,
      linkimage:backend,
    })   

    //liste des images
    const responseimages = await fetch(backend + 'listcarousel');
    const dataimages = await responseimages.json();
    this.setState({ imagescarousel: dataimages}) 
    
    //liste des categories
    const responsecategory = await fetch(backend+ 'listcategory');
    const datacategory = await responsecategory.json();
    this.setState({ listcategory: datacategory}) 

    //liste des partners
    const responsepartner = await fetch(backend+ 'listpartner');
    const datapartner= await responsepartner.json();
    this.setState({ listpartner: datapartner}) 

    //Image profil
    const responseimageprofil = await fetch(backend+ 'listimageprofil');
    const dataimageprofil = await responseimageprofil.json();
    this.setState({ listimageprofil: dataimageprofil}) 
    
    this.updateWindowDimensions();
    this.detailcategory();

    window.addEventListener("storage", this.detailcategory);
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('scroll', this.updateWindowDimensions);
      
  };

  componentWillMount () {
    window.removeEventListener("storage", this.detailcategory);
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('scroll', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight, scrollpage: window.scrollY });
  }

  scrolltop(){
    window.scrollTo(0, 0)
  }

  async detailcategory(){

    var idcat = ls.get('idcat', idcat);
    var name = ls.get('name', name);
    var description = ls.get('description', description);
    var imagecategory = ls.get('imagecategory', imagecategory);

    if(parseInt(idcat)>0){

      this.setState({
        idcategory: idcat,
        namecategory:name,
        descriptioncategory:description,
        imagecategory:imagecategory,
        isModalVisible:true
      })

      const response = await fetch(this.state.backend + 'listimagecategory',{                  
            method:'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: queryString.stringify({
              idcat:idcat
            })});
      const data = await response.json();
    
      this.setState({
        listimage:data,
      })

      var idcat = ls.remove('idcat');
      var name = ls.remove('name');
      var description = ls.remove('description');
      var imagecategory = ls.remove('imagecategory');

    }

  }


  async selectimagecategory(idcat,name,description,imagecategory){

    this.setState({
      idcategory: idcat,
      namecategory:name,
      descriptioncategory:description,
      imagecategory:imagecategory,
      isModalVisible:true
    })
  
    const response = await fetch(this.state.backend + 'listimagecategory',{                  
          method:'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: queryString.stringify({
            idcat:idcat
          })});
    const data = await response.json();
  
    this.setState({
      listimage:data,
    })
  
  }

  

  handlePointerMove = (value, index) => {
    this.setState({ ratingvalue: value}) 
  }

  visitpartner(linkpartner){
    window.open(linkpartner);
  }

  render() {

      const onPointerMove = (value,index) => this.handlePointerMove(value, index)

        return (
            
              <div>
                
                <div id="accueil">

                  {this.state.imagescarousel.length>0 ?

                      <Carousel fade className='w-100 d-block'>

                      {this.state.imagescarousel.map((lst) => {

                      return (

                            <Carousel.Item key={lst.idcarousel} interval={3500} style={{height:isBrowser?"90vh":"50vh"}}>
                          
                              <img style={{width:"100%",height:isBrowser?"90vh":"50vh", marginTop:"0px"}}  src={this.state.backend +  lst.imgcarousel } className='carousel-img' />
                              
                              <Carousel.Caption>

                                <p style={{fontSize:isBrowser?"2.5em":"1em"}} className='textombrenoir'>{lst.textcarousel}</p>
                                
                              </Carousel.Caption>

                            </Carousel.Item>

                              );
                            })} 


                      </Carousel>

                      :

                      <img style={{position:"absolute",width:"100%",height:"290px",marginBottom:"290px"}}  src={process.env.PUBLIC_URL + 'images/background.jpg'} />

                      }

                </div>

                <div>

                <BrowserView>
                <Modal
                        size='xl'
                        show={this.state.isModalVisible}
                        onHide={ () => this.setState({isModalVisible:false,idcategory: 0, namecategory:'', descriptioncategory:'',listimage:[],imagecategory:''})}
                        dialogClassName="modal-dialog"
                        aria-labelledby="example-custom-modal-styling-title" centered
                    >
                    
                    <Modal.Body>

                      <div className="container">
                        <div className="row">
                          <div className="col-7">
                            
                            {this.state.listimage.length>0 ?

                              <Carousel >

                              {this.state.listimage.map((lst) => {

                              return (

                                    <Carousel.Item key={lst.id_photo} interval={3000}>
                                  
                                      <img
                                        style={{width:"600px",height:"500px", marginTop:"0px", borderRadius:"10px"}}
                                        src={this.state.linkimage + lst.chemin}
                                      />

                                      <Carousel.Caption>

                                        <p style={{fontSize:"1.2em", color:"white", textAlign:"center"}} className='textombrenoir'>{lst.nom}</p>

                                      </Carousel.Caption>
                                                                                  
                                    </Carousel.Item>

                                );
                              })} 

                              </Carousel>

                              :

                              <img
                                style={{width:"500px",height:"500px", marginTop:"0px", borderRadius:"10px"}}
                                src={this.state.linkimage +  this.state.imagecategory}
                              />

                              }

                          </div>
                          <div className="col-4">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                  <p style={{fontWeight:"bold", textAlign:'center'}}>{this.state.namecategory}&nbsp;</p>
                                  <p>{this.state.descriptioncategory}</p>
                                  <Button className="btn " onClick={() => this.setState({isModalVisible:false,idcategory: 0, namecategory:'', descriptioncategory:'',listimage:[],imagecategory:''})} variant="primary">Close</Button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </Modal.Body>
                    </Modal>
                </BrowserView>

                <MobileView >

                <Modal
                        
                      show={this.state.isModalVisible}
                      onHide={ () => this.setState({isModalVisible:false,idcategory: 0, namecategory:'', descriptioncategory:'',listimage:[],imagecategory:''})}
                      dialogClassName="modal-dialog-mobile"
                      aria-labelledby="example-custom-modal-styling-title" centered
                    >
                    
                    <Modal.Body>

                        <div className="modal-infotype">

                          {this.state.listimage.length>0 ?

                            <Carousel >

                            {this.state.listimage.map((lst) => {

                            return (

                                  <Carousel.Item key={lst.id_photo} interval={3000}>
                                
                                    <img
                                      className="d-block w-100" style={{width:"80%",height:"30vh", marginTop:"0px", borderRadius:"10px"}}
                                      src={this.state.linkimage + lst.chemin}
                                    />

                                    <Carousel.Caption>

                                      <p style={{fontSize:"1.2em", color:"white", textAlign:"center"}} className='textombrenoir'>{lst.nom}</p>

                                    </Carousel.Caption>
                                                                                
                                  </Carousel.Item>

                              );
                            })} 

                            </Carousel>

                            :

                            <img
                            style={{width:"80%",height:"30vh", marginTop:"0px", borderRadius:"10px"}}
                            src={this.state.linkimageL +  this.state.imagecategory}
                            />

                            }

                                <div style={{margin:"20px",overflow:"auto", height:'220px', width:"40vh"}}>
                                    
                                    <div className="d-flex flex-column">

                                        <p style={{fontWeight:"bold", textAlign:'center'}}>{this.state.namecategory}&nbsp;</p>
                                        
                                    </div>

                                    <p>{this.state.descriptioncategory}</p>

                                </div>

                                <Button className="btn float-end" onClick={() => this.setState({isModalVisible:false,idcategory: 0, namecategory:'', descriptioncategory:'',imagecategory:'',listimage:[]})} variant="primary">Close</Button>

                        </div>    

                    </Modal.Body>
                    </Modal>

                </MobileView>
                                        
                   
                </div>

                    
                    {this.state.scrollpage>200 ?

                        <Zoom>
                          <button className="bnttop" onClick={() => this.scrolltop()} style={{width:'50px', height:'50px', marginBottom:"70px",padding:"0%"}}><span><KeyboardDoubleArrowUpIcon /></span></button>
                        </Zoom>

                        :

                        null
                    
                    }
                    
                    <div id="gallery" style={{backgroundImage:`url("/images/bgcategory.jpg")`, backgroundRepeat:"no-repeat",backgroundSize: "cover"}}>

                      <Fade bottom>

                        <div className='align-items-center'> 

                            <p style={{color:"#db01d4", fontWeight:"bold", fontSize:"2em", textAlign:'center', paddingTop:'20px'}}>Gallery</p>

                        </div>
                        
                      </Fade>

                      <div className="listedata">

                        {this.state.listcategory.map((category) => {
                          return (

                                  <Zoom  bottom>

                                      {isBrowser?

                                        <Card key={category.id_category} className="cardlist" style={{width:"500px", margin:"25px"}}>
                                        <Card.Body>
                                          <div className='d-flex flex-row'>

                                            <img src={this.state.linkimage + category.category_img} className='imgcategory'/>

                                            <div className='d-flex flex-column' style={{paddingLeft:"20px"}}>
                                              <Card.Title>{category.category_name}</Card.Title>
                                              <p> {category.category_description}</p>
                                            </div>

                                          </div>

                                          {isBrowser?

                                            <div className='d-flex justify-content-end'>
                                            < Button variant="primary" onClick={() => this.selectimagecategory(category.id_category,category.category_name,category.category_description,category.category_img)}>See all pictures</Button>
                                            </div>

                                            :
                                            
                                            <div className='d-flex justify-content-center'>
                                              <Button variant="primary" onClick={() => this.selectimagecategory(category.id_category,category.category_name,category.category_description,category.category_img)}>See all pictures</Button>
                                            </div>

                                          }
                                            
                                        </Card.Body>
                                        </Card>

                                      :

                                       <Card key={category.id_category} className="cardlist" style={{width:"40vh", margin:"10px"}}>
                                        <Card.Body>

                                          <div className='d-flex justify-content-center'>
                                            <img src={this.state.linkimage + category.category_img} className='imgcategory'/>
                                          </div>

                                          <p style={{textAlign:"center", marginTop:"10px", fontWeight:"bold", fontSize:"24px"}}>{category.category_name}</p>
                                          <p style={{textAlign:"left",paddingLeft:"20px"}}> {category.category_description}</p>

                                          <div className='d-flex justify-content-center' style={{paddingLeft:"20px"}}>
                                            
                                          </div>

                                          <div className='d-flex justify-content-center'>
                                            <Button variant="primary" onClick={() => this.selectimagecategory(category.id_category,category.category_name,category.category_description,category.category_img)}>See all pictures</Button>
                                          </div>
                                            
                                        </Card.Body>
                                        </Card>

                                      }

                                  </Zoom > 

                            );
                        })}

                      </div>

                    
                    </div>

                    <div id="aboutme" >
                        
                        <Fade bottom>
  
                          <div>    
  
                              <p style={{color:"#db01d4", fontWeight:"bold", fontSize:"2em", textAlign:'center', paddingTop:'20px'}}>About me</p>
  
                              <div class="container">

                                <div className="row justify-content-md-center">
  
                                  <div className="col-md-auto align-item-center" style={{textAlign:"center"}}>

                                  {this.state.listimageprofil.map((imgprof) => {
                                    return (

                                            <Fade bottom>

                                                <img src={this.state.linkimage + imgprof.img_profil}  style={{width:"250px", height:"250px", borderRadius:"250px", marginLeft:"5px"}}/>

                                            </Fade> 

                                      );
                                  })}
   
                                  </div>
  
                                  <div className="col col-lg-6"style={{marginTop:"40px"}} >
                                    <p style={{fontSize:"40px",paddingLeft:"20px"}}>Photographer</p>
                                    <p style={{fontSize:"20px",paddingLeft:"20px"}}>My Name is Andriniaina S R Armel.</p>
                                    <p style={{fontSize:"20px",paddingLeft:"20px"}}>Known as Armel Madagascar in the art of photography.</p>
                                  </div>
                                  
                                </div>
  
                              </div>
  
                          </div>
  
                        </Fade>
  
                      </div> 

                    <div id="partner" >
                        
                      <Fade bottom>

                      <p style={{color:"#db01d4", fontWeight:"bold", fontSize:"2em", textAlign:'center', paddingTop:'20px'}}>Our Partners / Our Clients</p>

                        <div className='align-items-center listedata'>    

                            {this.state.listpartner.map((pat) => {
                              return (

                                      <Fade bottom>

                                        <Card key={pat.id_partners} className="cardlistpartner">
                                          <Card.Body>
                                            <div className='d-flex flex-row'>

                                              

                                              <div className='d-flex flex-column justify-content-center' style={{paddingLeft:"20px"}}>
                                                <img src={this.state.linkimage + pat.partner_logo} className='imgpartner'/>
                                                <Card.Title>{pat.partner_name}</Card.Title>
                                                <Button variant="primary" onClick={() => this.visitpartner(pat.partner_url)}>Visit</Button>
                                              </div>

                                            </div>
                                            <div className='d-flex justify-content-end'>
                                              
                                            </div>
                                            
                                          </Card.Body>
                                        </Card>

                                      </Fade> 

                                );
                            })}


                        </div>

                      </Fade>

                    </div> 
                      

                    <BrowserView>

                      <div id="contact" className="justify-content-center footer">

                       <div className="container">

                          <div className="row">

                            <Fade right>
                              <div className="col-3">
                                <p style={{color:"white",fontSize:'20px', marginTop:30}}>Armel Madagascar</p>
                              </div>
                            </Fade>

                            <Zoom>
                              <div className="col-6">
                                <p className="textombrenoir" style={{ color:'white',fontSize:"18px", marginTop:30, textAlign:"center"}}>
                                  Copyright &copy; {new Date().getFullYear()} Tous les droits réservés à Armel Madagascar.
                                </p>
                              </div>
                            </Zoom>

                            <div className="col-3">
                              <div className="row">
                                  <div className="text-center">
                                    <Fade right>
                                      <div className="py-1">
                                          <a href="https://web.facebook.com/ArmelMadagascar" className="iconsociaux" target="_blank"><FacebookIcon style={{fontSize:'40' , color: '#db01d4', margin:10}}/></a>
                                          <a href="https://mg.linkedin.com/in/armel-madagascar-05196597" className="iconsociaux" target="_blank" ><LinkedInIcon style={{fontSize:'40', color: '#db01d4', margin:10}}/></a>
                                          <a href="https://www.instagram.com/armel_madagascar/" className="iconsociaux" target="_blank"><TwitterIcon style={{fontSize:'40', color: '#db01d4', margin:10}}/></a>
                                          <a href="mailto:armeltourguide@gmail.com" className="iconsociaux" target="_blank"><ForwardToInboxIcon style={{fontSize:'40', color: '#db01d4', margin:10}}/></a>
                                      </div>
                                    </Fade>
                                  </div>
                              </div>
                            </div>

                          </div>

                        </div>

                      </div>

                    </BrowserView>

                    <MobileView>

                      <div id="contact" className="justify-content-center footer">

                        <Zoom>
                          <div>
                            <p style={{color:"white",fontSize:'20px', paddingTop:40, textAlign:"center"}}>Armel Madagascar</p>
                          </div>
                        </Zoom>

                        <Zoom>
                          <div>
                            <p className="textombrenoir" style={{ color:'white',fontSize:"16px", marginTop:30, textAlign:"center"}}>
                              Copyright &copy; {new Date().getFullYear()} Tous les droits réservés à Armel Madagascar.
                            </p>
                          </div>
                        </Zoom>

                        <Zoom>
                          <div className="d-flex flex-row justify-content-center">
                              <a href="https://web.facebook.com/ArmelMadagascar" className="iconsociaux" target="_blank"><FacebookIcon style={{fontSize:'40' , color: '#db01d4', margin:15}}/></a>
                              <a href="https://mg.linkedin.com/in/armel-madagascar-05196597" className="iconsociaux" target="_blank" ><LinkedInIcon style={{fontSize:'40', color: '#db01d4', margin:15}}/></a>
                              <a href="https://www.instagram.com/armel_madagascar/" className="iconsociaux" target="_blank"><TwitterIcon style={{fontSize:'40', color: '#db01d4', margin:15}}/></a>
                              <a href="mailto:armeltourguide@gmail.com" className="iconsociaux" target="_blank"><ForwardToInboxIcon style={{fontSize:'40', color: '#db01d4', margin:15}}/></a>
                          </div>
                        </Zoom>

                      </div>

                    </MobileView>
                   

              </div>
        );
      }
  
}

export default Accueil;
