import '../App.css';
import React,  {Alert,Component} from 'react';
import { Nav,Navbar,Button,Form,Container,Row,Col} from 'react-bootstrap';
import axios from 'axios';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import queryString from 'query-string';
import ls from "local-storage";
import AlertTitle from '@mui/material/AlertTitle';
import { Redirect  } from 'react-router-dom';
import Loader from "react-js-loader";

var errocode = 0;

class Login extends Component{
    
    constructor(props) {
    super(props);
    this.state = {
      mail: '',
      password: '',
      errorform: false,
      errormessage:'',
      signin : false,
      show_input:'text',
      tokenexist: true,
      loadershow : false,


    };
        
  }
    
    componentDidMount() {
      
        
    };
    

connexionuser(){
    
  var backend = ls.get('backend');

    fetch(backend + `login`,{                  
  
      method:'post',
      headers: {'Content-Type':'application/x-www-form-urlencoded'},
      body: queryString.stringify({
        email:this.state.mail,
        password:this.state.password,
      })

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    const token = JSON.stringify(data.token).replace(/\"/g, "");

    ls.set('token', token);

    this.setState({
      loadershow: true,
      errorform: false,
      errormessage:'',             
      signin: true,

    });   

    window.dispatchEvent(new Event("storage"));
    
    document.location.href = '/Accueil';

    })
    .catch(err => {

      this.setState({ errorform: true, errormessage: JSON.stringify(err.message).replace(/\"/g, ""),loadershow: false });

    });  

}
   
    
  render() {

          return (
              
                <div className="login" style={{backgroundImage:`url("/images/bgcategory.jpg")`, backgroundRepeat:"no-repeat",backgroundSize: "cover",width:"100%", height:"100vh"}}>
                    
                    <div className="form-group d-flex justify-content-center" style={{marginTop:'20px'}}>
                      <input type="email"  style={{color:"rgba(0, 0, 0, 0.35)", width:"300px", height:"40px",padding:"10px", fontSize:'18px'}} placeholder="Login" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value})} />
                    </div>

                    <div className="form-group d-flex justify-content-center" style={{marginTop:'20px'}}>
                      <input type="password"  style={{color:"rgba(0, 0, 0, 0.35)", width:"300px",  height:"40px",padding:"10px", fontSize:'18px'}} placeholder="Password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value})} />
                    </div>

                    <div className="d-flex justify-content-center">
                      <Button className="btn" style={{ width:200, height: 48,  borderRadius:'8px',  marginTop:'20px',  marginBottom:'50px', }} variant="primary" onClick={() => this.connexionuser()} >Login</Button>
                    </div> 

                    {this.state.errorform == true ? 

                      <p style={{color:"#d9534f", fontSize:"30px", fontWeight:"bold"}}>{this.state.errormessage}</p>

                      :

                      null

                    }

                    {this.state.signin == true ?
                      

                      <Redirect push to="/Accueil"/>
                        

                      :

                      null

                    }

                    {this.state.loadershow == true ?
                      

                      <Loader type="spinner-cub" bgColor={'#0275d8'} title={"Loading ..."} color={'#0275d8'} size={100} />
                        

                      :

                      null

                    }
                    

                </div>
          );
        }
    
}

export default Login;
